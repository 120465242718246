import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-3">
                <p>
                    Els test psicoprofessionals són una eina essencial a l’hora de triar persones en
                    un procés de selecció. És per això que cada persona té un perfil únic en base a
                    la seva personalitat.
                </p>

                <p>
                    Des de <strong>iOpos Acadèmia</strong> som especialistes i t’oferim un servei
                    personalitzat perquè puguis afrontar la teva oposició amb les màximes garanties.
                </p>

                <p>
                    Si vols obtenir el teu perfil policial dintre dels ítems amb les competències
                    exigides a les convocatòries de{" "}
                    <strong>Mossos d’Esquadra, Guàrdia Urbana</strong> o{" "}
                    <strong>Policies Locals</strong>, amb anàlisi de les preguntes i la teva gràfica
                    personalitzada, aquesta formació és per tu.
                </p>

                <p>
                    Podràs triar entre el <strong>test competencial</strong>, el{" "}
                    <strong>test de personalitat</strong> o el <strong>test clínic</strong>.
                </p>

                <p>
                    A l’hora de reservar hauràs de contactar via mail amb{" "}
                    <Link
                        className="text-cpurple font-semibold"
                        href="mailto:testpsicos@iopos.academy"
                    >
                        testpsicos@iopos.academy
                    </Link>{" "}
                    per iniciar el procés de formació. Se t’enviarà el test escollit i, un cop
                    realitzat, obtindràs la teva gràfica personalitzada.
                </p>

                <p>
                    Posteriorment, reservaràs via mail els <strong>45 minuts</strong> a través de{" "}
                    <strong>ZOOM</strong> per analitzar els ítems i competències.
                </p>

                {/*<div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/YUe0b5qv5F0"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>*/}
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Descobreix el teu perfil policial i prepara't amb garanties per a les oposicions de Mossos d'Esquadra, Guàrdia Urbana o Policies Locals amb el nostre servei personalitzat d'anàlisi psicològica. Reserva ara i obté la teva gràfica personalitzada amb orientació experta a través de Zoom!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
